/* eslint-disable jsx-a11y/label-has-for */
// @noflow
import { UseSelectGetItemPropsOptions, useSelect } from 'downshift'
import React, { useCallback } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Image from '@/components/shared/elements/Image/Image'

type Option = {
  value: string | number
  label: string
  id: string
}

type OptionProps = {
  option: Option
  value?: Option | null
  optionAccessibilityAttributes: (
    options: UseSelectGetItemPropsOptions<Option>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any // have to use any here due to the modules typing
}

type Props = {
  initialValue?: Option | null
  label: string
  options: Array<Option>
  disabled?: boolean
  onChange: (value: Option) => void
}

const OptionComponent = ({
  option,
  value,
  optionAccessibilityAttributes
}: OptionProps): JSX.Element => {
  const baseClass = 'selector__option'

  return (
    <div
      className={`
      ${baseClass}__list-item
      ${value?.value === option.value && 'selected'}
      `}
      role="button"
      tabIndex={0}
      {...optionAccessibilityAttributes({
        item: option
      })}
    >
      <p className="text-regular-18 no-scale">{option.label}</p>
    </div>
  )
}

const Select = ({
  initialValue,
  label,
  options,
  disabled = false,
  onChange
}: Props): JSX.Element => {
  const baseClass = 'selector'
  const { windowWidth } = useWindowSize()
  const menuRef = React.useRef<HTMLDivElement>(null)

  const itemToString = useCallback((item: Option | null) => {
    return item ? item.label : ''
  }, [])

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps
  } = useSelect({
    initialSelectedItem: initialValue,
    items: options,
    itemToString,
    onSelectedItemChange: (data) => {
      if (!data.selectedItem) return
      onChange(data.selectedItem)
    }
  })

  return (
    <div className={`${baseClass}__container`}>
      <label className="text-regular-18 no-scale" {...getLabelProps()}>
        {label}
      </label>
      <div
        className={`${baseClass}__header ${disabled ? 'disabled' : ''} ${
          isOpen ? 'open' : ''
        }`}
        {...getToggleButtonProps()}
        role="button"
        tabIndex={0}
      >
        <p className="text-regular-18 no-scale">{selectedItem?.label}</p>
        <span className={`${baseClass}__chevron-icon ${isOpen ? 'open' : ''}`}>
          <Image
            alt=""
            slug="chevron-blue-500"
            image={{
              width: windowWidth < BREAKPOINTS.lg ? 12 : 20,
              height: windowWidth < BREAKPOINTS.lg ? 12 : 20
            }}
          />
        </span>
      </div>
      <div
        className={`${baseClass}__list-container ${!isOpen ? 'hidden' : ''}`}
        {...getMenuProps({ ref: menuRef })}
      >
        <ul className={`${baseClass}__list`}>
          {isOpen &&
            options.map((option) => (
              <OptionComponent
                key={option.value}
                option={option}
                value={selectedItem}
                optionAccessibilityAttributes={getItemProps}
              />
            ))}
        </ul>
      </div>
    </div>
  )
}

export type { Props, Option }
export default Select
